import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
// import Seo from "../../../components/seo";
import { BrowserView } from "react-device-detect";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import { StaticImage } from "gatsby-plugin-image";
import {
  MDXLayoutComponents,
  MDXGlobalComponents,
} from "../../../components/mdx/";
import {
  PageHeader,
  Typography,
  ConfigProvider,
  Affix,
  Row,
  Col,
  List,
  Alert,
  Modal,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Title, Text, Paragraph } = Typography;

export default function PageTemplate({ data: { mdx } }) {
  const textSubtitle =
    mdx.frontmatter.language === "nl" ? "Een demo " : "Made by ";

  useEffect(() => {
    ConfigProvider.config({
      theme: {
        primaryColor: "#153A80",
        successColor: "#8FB132",
        infoColor: "#153A80",
      },
    });
    if ("parentIFrame" in window) {
      window.parentIFrame.getPageInfo((pageInfo) => {
        if (pageInfo.scrollTop < pageInfo.offsetTop) {
          setVerticalModalPosition(pageInfo.offsetTop * 0.25);
        } else {
          setVerticalModalPosition(pageInfo.scrollTop - pageInfo.offsetTop);
        }
        setVerticalSidebarPosition(pageInfo.scrollTop * 0.9);
        setVerticalHeightPosition(pageInfo.documentHeight * 0.5);
        setVerticalOffsetPosition(pageInfo.offsetTop);
      });
    }
  });

  const [verticalModalPosition, setVerticalModalPosition] = useState(0);
  const [verticalSidebarPosition, setVerticalSidebarPosition] = useState(0);
  const [verticalHeightPosition, setVerticalHeightPosition] = useState(650);
  const [verticalOffsetPosition, setVerticalOffsetPosition] = useState(0);

  const [isModalOneOpen, setIsModalOneOpen] = useState(false);
  const showModalOne = () => {
    setIsModalOneOpen(true);
  };
  const handleOkOne = () => {
    setIsModalOneOpen(false);
  };

  const [isModalTwoOpen, setIsModalTwoOpen] = useState(false);
  const showModalTwo = () => {
    setIsModalTwoOpen(true);
  };
  const handleOkTwo = () => {
    setIsModalTwoOpen(false);
  };

  const [isModalThreeOpen, setIsModalThreeOpen] = useState(false);
  const showModalThree = () => {
    setIsModalThreeOpen(true);
  };
  const handleOkThree = () => {
    setIsModalThreeOpen(false);
  };

  return (
    <div
      style={{
        backgroundColor: "#f1f3f5",
        minHeight: "100vh",
        paddingTop: "55px",
        position: "relative",
      }}
    >
      {/* <Seo
        description={`This is an interactive chart made for ${mdx.frontmatter.client}. This chart is made with inFlow interactive charts (www.inflowchart.com). At inFlow we help professional organisations create interactive decision-making tools such as flow charts, decision trees or algorithms. Improve your process and increase engagement with our world-class tools.`}
        title={`${mdx.frontmatter.title} by ${mdx.frontmatter.client}`}
      /> */}
      <PageHeader
        style={{
          backgroundColor: "#fff",
          height: "55px",
          left: "0",
          position: "fixed",
          top: "0",
          width: "100%",
          zIndex: "15000",
        }}
        title={
          <Title
            style={{
              fontFamily: "Roboto, sans-serif",
              fontSize: "16px",
              marginTop: "0px",
            }}
          >
            {mdx.frontmatter.title}
          </Title>
        }
        subTitle={
          <>
            <BrowserView style={{ position: "relative", top: "3px" }}>
              <Text type="secondary" className="hide-for-small">
                {textSubtitle}{" "}
              </Text>
              <a
                className="hide-for-small"
                href="https://www.inflowchart.com?utm=examples"
                rel="noreferrer noopener"
                target="_blank"
              >
                inFlow interactive charts
              </a>
            </BrowserView>
          </>
        }
      />
      <Row>
        <Col span={21} style={{ padding: "0 20px" }}>
          <Alert
            message="This tool will guide you through the process of using the DEP to find the most useful evidence about what works in global development. The tool is interactive and will ask questions about your needs and what you are finding in DEP, to give you guidance at each step. We recommend that you open it in a separate tab."
            type="info"
            closable
            style={{ marginTop: "20px" }}
          />
          <Alert
            message="You can learn about the DEP and smart ways to find evidence in the sidebar (right). You will receive hints to refer to the sidebar, at different stages of the journey"
            type="info"
            closable
            style={{ marginTop: "20px" }}
          />
          <Row
            justify="space-between"
            align="middle"
            style={{ marginTop: "20px" }}
          >
            <Col xs={8} md={4}>
              <StaticImage
                src="../../../images/client/3ieimpact/logo1.png"
                alt="Logo"
              />
            </Col>
            <Col xs={6} md={4}>
              <StaticImage
                src="../../../images/client/3ieimpact/logo2.png"
                alt="Logo"
              />
            </Col>
          </Row>
          <MDXProvider
            components={{
              ...MDXLayoutComponents,
              ...MDXGlobalComponents,
            }}
          >
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </MDXProvider>
        </Col>
        <Col span={3} style={{ backgroundColor: "#e2e6ea", padding: "50px 15px 0 15px" }}>
          <Affix offsetTop={200}>
          <Title
            style={{
              fontFamily: "Roboto, sans-serif",
              fontSize: "16px",
              marginBottom: "15px",
              marginTop: verticalSidebarPosition
            }}
          >
            Guidance
          </Title>
          <p
            style={{ color: "#153A80", cursor: "pointer" }}
            onClick={showModalOne}
          >
            <InfoCircleOutlined style={{ marginRight: "5px" }} />
            Search tips
          </p>
          <p
            style={{ color: "#153A80", cursor: "pointer" }}
            onClick={showModalTwo}
          >
            <InfoCircleOutlined style={{ marginRight: "5px" }} />
            Filters
          </p>
          <p
            style={{ color: "#153A80", cursor: "pointer" }}
            onClick={showModalThree}
          >
            <InfoCircleOutlined style={{ marginRight: "5px" }} />
            Resources for using evidence
          </p>
          <Modal
            title="Search tips"
            open={isModalOneOpen}
            onOk={handleOkOne}
            onCancel={handleOkOne}
            footer={null}
            width={800}
            style={{ marginTop: verticalModalPosition }}
            bodyStyle={{ maxHeight: verticalHeightPosition, overflow: "scroll" }}
          >
            <Paragraph>
              Exploring the 3ie Development Evidence Portal (DEP) can be done
              using two main methods: <Text strong>filters</Text> and{" "}
              <Text strong>basic search</Text>. Depending on the situation,
              either method can be more effective at finding what you’re looking
              for. It may require some experimentation to determine which
              approach to use. To help you decide whether to begin with filters
              or basic search, here are some tips:
            </Paragraph>
            <Title
              level={4}
              style={{
                fontFamily: "Roboto, sans-serif",
                fontSize: "16px",
                marginBottom: "15px",
              }}
            >
              Basic search
            </Title>
            <Paragraph>
              The basic search presents a familiar way to look for information
              in the DEP: just like using Google, you type your terms into the
              search bar, and hit Enter. This can be useful when you're
              interested in something specific that isn't captured by one of the
              filtering options, or that cross-cuts multiple filtering options.
              For example, if you are interested in particular populations like
              adolescents, there currently is no filter for population. This
              works better as a basic search, e.g., adolescent health.
            </Paragraph>
            <Paragraph>
              Even if you start with a basic search, you can always apply
              filters afterwards.
            </Paragraph>
            <Title
              level={4}
              style={{
                fontFamily: "Roboto, sans-serif",
                fontSize: "16px",
                marginBottom: "15px",
              }}
            >
              Tips for basic search
            </Title>
            <Paragraph>
              <List size="small" bordered>
                <List.Item>
                  By default, basic search looks for studies that match ALL your
                  search terms. You can override this by typing OR (in capital
                  letters) between your terms, e.g.,{" "}
                  <Text italic>vaccination</Text> OR{" "}
                  <Text italic>immunization</Text>.
                </List.Item>
                <List.Item>
                  You can use quotation marks to find exact phrases (e.g., “
                  <Text italic>climate change</Text>”). This helps to narrow
                  your search, but you may want to remove them if results are
                  too few
                </List.Item>
                <List.Item>
                  You can use an asterisk to truncate terms and find different
                  variants of a word. For example, searching{" "}
                  <Text italic>immuni</Text>* will find studies containing
                  "immunization", "immunisation", "immunize", "immunity", etc.
                </List.Item>
              </List>
            </Paragraph>
            <Title
              level={4}
              style={{
                fontFamily: "Roboto, sans-serif",
                fontSize: "16px",
                marginBottom: "15px",
              }}
            >
              Filters
            </Title>
            <Paragraph>
              <Text strong>
                Applying filters alone can be useful when you are interested in
                all research with a particular feature
              </Text>
              , especially when there's no single set of terms that describe
              everything you're interested in. Examples include:
            </Paragraph>
            <Paragraph>
              <List size="small" bordered>
                <List.Item>
                  You're interested in all research from a particular country
                  that analyzes an intervention's effects on men and women
                  separately.
                </List.Item>
                <List.Item>
                  You're interested in the most recent research on a broad
                  thematic area, such as a sector or sustainable development
                  goal.
                </List.Item>
              </List>
            </Paragraph>
            <Paragraph>
              The advantage to using filters this way is that it removes some of
              the guesswork involved in selecting the right search terms. For
              example, you can use the country filter to find all research on
              Côte d'Ivoire/Ivory Coast, without having to worry about which
              name authors have used for the country. Using filters also helps
              ensure you will find what you're looking for even if study authors
              use atypical language to describe their research (i.e., they use
              terms that you wouldn't think to search for). To view the full
              list of filters and their definitions, refer to ‘Filters’ .
            </Paragraph>
            <Title
              level={4}
              style={{
                fontFamily: "Roboto, sans-serif",
                fontSize: "16px",
                marginBottom: "15px",
              }}
            >
              Advanced search
            </Title>
            <Paragraph>
              The <Text italic>advanced search</Text> feature allows you to
              create more complex queries. On the DEP homepage, expand the
              ‘Advanced search’ module and click on the ‘Help’ button for
              detailed instructions.
            </Paragraph>
          </Modal>
          <Modal
            title="Filters"
            open={isModalTwoOpen}
            onOk={handleOkTwo}
            onCancel={handleOkTwo}
            footer={null}
            width={800}
            style={{ marginTop: verticalModalPosition }}
            bodyStyle={{ maxHeight: verticalHeightPosition, overflow: "scroll" }}
          >
            <Paragraph>
              To make it easy to find relevant evidence,{" "}
              <Text strong>
                3ie has classified thousands of studies in the DEP based on the
                interventions evaluated, outcomes measured, international
                classifications, and other characteristics
              </Text>
              . This allows you to filter for studies with particular
              characteristics of interest (and combine filtering with basic
              search). A list of all these variables, along with their
              definitions, is below.
            </Paragraph>
            <Paragraph>
              <Text strong>Interventions</Text> and <Text strong>outcomes</Text>{" "}
              are organized into a hierarchical taxonomy which can be explored{" "}
              <a
                href="https://developmentevidence.3ieimpact.org/taxonomy-search"
                target="_blank"
                rel="noreferrer noopener"
              >
                here
              </a>
              . If you identify an intervention or outcome of interest to you,{" "}
              <Text strong>
                click the magnifying glass to search DEP for studies with that
                intervention/outcome
              </Text>
              . If there are multiple interventions/outcomes you are interested
              in, make note of the desired intervention/outcome categories, go
              to the main DEP page, and use Advanced Search to find studies with
              any of your categories.
            </Paragraph>
            <Paragraph>
              Other variables that can be queried using filters (or advanced
              search) include:
            </Paragraph>
            <Paragraph>
              <List size="small" bordered>
                <List.Item>
                  <Text strong>Type of study</Text> allows you to restrict your
                  search to impact evaluations, systematic reviews, or evidence
                  gap maps.{" "}
                </List.Item>
                <List.Item>
                  <Text strong>Sectors</Text> and <Text strong>Themes</Text> are
                  assigned according to the World Bank’s classification system
                </List.Item>
                <List.Item>
                  <Text strong>OECD DAC</Text> Sectors, CRS codes, and Voluntary
                  codes
                </List.Item>
                <List.Item>
                  The United Nations{" "}
                  <Text strong>Sustainable Development Goals</Text>
                </List.Item>
                <List.Item>
                  Impact <Text strong>evaluation methods</Text> used in the
                  study{" "}
                </List.Item>
                <List.Item>
                  Geographic <Text strong>regions</Text> and{" "}
                  <Text strong>countries</Text>{" "}
                </List.Item>
                <List.Item>
                  <Text strong>Fragility and conflict-affected status</Text> of
                  the country where a study was conducted (according to World
                  Bank Group's list of fragile and conflict-affected situations)
                </List.Item>
                <List.Item>
                  <Text strong>Equity focus</Text> indicates whether and how
                  gender or equity is considered in a study.
                </List.Item>
                <List.Item>
                  <Text strong>Equity dimension</Text> indicates which
                  dimensions of gender and/or equity are addressed in the study
                  (e.g. sex, socioeconomic status, disability, etc.).
                </List.Item>
                <List.Item>
                  <Text strong>Year of publication</Text>
                </List.Item>
                <List.Item>
                  The <Text strong>funder of the research</Text>
                </List.Item>
                <List.Item>
                  <Text strong>Dataset availability</Text> to the public and
                  whether the study was
                  <Text strong> externally registered</Text>
                </List.Item>
              </List>
            </Paragraph>
          </Modal>
          <Modal
            title="Resources for using evidence"
            open={isModalThreeOpen}
            onOk={handleOkThree}
            onCancel={handleOkThree}
            footer={null}
            width={800}
            style={{ marginTop: verticalModalPosition }}
            bodyStyle={{ maxHeight: verticalHeightPosition, overflow: "scroll" }}
          >
            <Paragraph>
              Finding relevant evidence is just the first step in making
              evidence-informed decisions. Below is a list of resources on how
              to use evidence in decision making. We plan to add more resources
              to this list as we find them, so keep an eye out for updates!
            </Paragraph>
            <Paragraph>
              <List size="small" bordered>
                <List.Item>
                  The Abdul Lateef Jameel Poverty Action Lab (J-PAL) has a{" "}
                  <a
                    href="https://www.povertyactionlab.org/page/evidence-policy-resources"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    list
                  </a>{" "}
                  of videos, guides, and papers on using evidence in policy
                  making.
                </List.Item>
                <List.Item>
                  The World Health Organization has an{" "}
                  <a
                    href="https://www.who.int/publications/i/item/9789240039872"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    official guide
                  </a>{" "}
                  and a{" "}
                  <a
                    href="https://evidence-impact.org/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    resource respository
                  </a>{" "}
                  on evidence-informed decision making.
                </List.Item>
                <List.Item>
                  The Results First™ initiative offers a set of{" "}
                  <a
                    href="https://evidence2impact.psu.edu/what-we-do/research-translation-platform/results-first-resources/evidence-based-policymaking-resource-center/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    resources
                  </a>{" "}
                  and a{" "}
                  <a
                    href="https://www.pewtrusts.org/~/media/assets/2014/11/evidencebasedpolicymakingaguideforeffectivegovernment.pdf"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    summary paper
                  </a>
                  , aimed specifically at government agencies, on using evidence
                  and data to guide policy and funding decisions.
                </List.Item>
                <List.Item>
                  The VakaYiko Consortium has a{" "}
                  <a
                    href="https://www.inasp.info/sites/default/files/2018-04/EIPM%20Toolkit-Ed2-FULL.pdf"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    toolkit
                  </a>{" "}
                  on evidence-informed policy making with modules on finding
                  evidence, assessing evidence, and communicating evidence.
                </List.Item>
                <List.Item>
                  If you’re in the mood for a longer read, we recommend the book{" "}
                  <i>
                    <a
                      href="https://books.google.co.uk/books?hl=en&lr=&id=BN1oAgAAQBAJ&oi=fnd&pg=PP1&dq=Evidence-Based+Policy:+A+Practical+Guide+to+Doing+It+Better&ots=F36DfekLx_&sig=vQYvj1c_xbSqOZMpygnEIBbIV0o&redir_esc=y#v=onepage&q=Evidence-Based%20Policy%3A%20A%20Practical%20Guide%20to%20Doing%20It%20Better&f=false"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Evidence-Based Policy: A Practical Guide to Doing It
                      Better
                    </a>{" "}
                  </i>
                  (2012) by Nancy Cartwright and Jeremy Hardie. In addition to a
                  thorough discussion of the theory behind evidence-informed
                  decision making, the authors provide practical guidance for
                  policy makers and practitioners to think through how evidence
                  from one context will transfer to another.
                </List.Item>
              </List>
            </Paragraph>
          </Modal>
          </Affix>
        </Col>
      </Row>

      <div data-iframe-height></div>
      <script
        type="text/javascript"
        src="/js/iframeresizer/4.2.9/iframeResizer.contentWindow.min.js"
      ></script>
    </div>
  );
}

export const pageQuery = graphql`
  query LayoutExamplesQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        added
        client
        edit
        language
        shorturl
        slug
        source
        title
      }
    }
  }
`;
